<template>
  <section class="background--views">
    <v-container>
      <RolesTable :updated="updated" name="RolesTable" @updateRole="updateRole" @updatedComplete="updated = false"></RolesTable>
    </v-container>
  </section>
</template>

<script>
// Domains
import Roles from "../domain/Roles";

export default {
  name: "Roles",
  components: {
    RolesTable: () => import("../components/roles/RolesTable.vue"),
  },
  data: () => ({
    component: "RolesTable",
    updated: false,
  }),
  methods: {
    async updateRole(user) {
      try {
        console.log("updateRole desde Role.vue", user);
        await Roles.updateRole(user)
        this.updated = true;
      } catch (error) {
        console.warn(error);
      }
    },
  },
};
</script>